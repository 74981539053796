const foundation = require('foundation-sites');
const $ = jQuery;
const readmore = require('readmore-js');
$(document).foundation();
var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
if (supportsTouch) {
  jQuery('body').addClass('touch-device');
}

import './plugins';

$('.about-me').readmore({
  collapsedHeight: 900,
  moreLink: '<a href="#" class="read-more">weiterlesen</a>',
  lessLink: '<a href="#" class="read-more">weniger anzeigen</a>',
});

var $imageSlider = $('.slick-image-slider').slick({
  autoplay: true,
  speed: 900,
  loop: true,
  pauseOnHover: false,
  autoplaySpeed: 3000,
  slidesToShow: 3,
  responsiveFirst: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      }
    },
  ]

});